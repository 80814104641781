* {
    margin: 0;
    padding: 0;
}

.container {
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.pop_up {
    min-width: 250px;
    background-color: #FFF;
    border-radius: 5px;
    padding: 10px;
}

.pop_up div {
    font-size: 14px;
    margin: 5px 0;
}

/* ::-webkit-scrollbar {
    width: 0px;
} */

.ant-table ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #E1E1E1;
}

.configAdd ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #E1E1E1;
}

.ant-message-notice-content {
    padding: 15px 24px !important
}

.ant-message .anticon {
    font-size: 24px !important;
}

.ant-message {
    font-size: 18px !important;
}

/*默认样式设置*/

/* .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align:left !important;
} */

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
    border-right: 3px solid #FFFFFF !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    border-bottom: 3px solid #FFFFFF !important;
}

.ant-table-tbody>tr>td {
    border-bottom: none !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
    border-top: none !important;
}

.ant-table.ant-table-bordered>.ant-table-container {
    border-left: none !important;
}

.ant-checkbox-group {
    width: 100%;
}

/* .searchModal .ant-checkbox-wrapper {
    width: 32%;
} */

.ant-table-cell .ant-checkbox-wrapper {
    width: 22%;
}

.ant-table-selection-col {
    width: 76px !important;
}

th {
    background: #FFEFDB !important;
    font-size: 20px !important;
    font-weight: bold !important;
}

.form_table th {
    font-size: 16px !important;
}

.ant-table-body {
    overflow-x: auto
}

.form_table .ant-table-summary>tr>td {
    background: #FFEFDB;
    font-size: 14px;
    font-weight: bold;
}

.cost_list .ant-table-summary>tr>td {
    background: #F7F7F7;
    font-size: 16px;
    font-weight: bold;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px !important;
    height: 0px !important;
}

.ant-table-cell {
    font-size: 16px;
    color: #333333 !important;
}

.ant-table-cell .ant-form-item {
    margin-bottom: 0;
}

.light-row {
    background-color: #F7F7F7;
}

.light-row .ant-input,
.light-row .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.light-row .ant-select-selection-search-input,
.light-row .ant-picker {
    background-color: #FFFFFF !important;
}

.dark-row {
    background-color: #FFFFFF;
}

.dark-row .ant-input,
.dark-row .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.dark-row .ant-select-selection-search-input,
.dark-row .ant-picker {
    background-color: #eff1f4 !important;
}

.ant-picker {
    width: 100%;
    background: none !important;
}

.ant-picker-range {
    width: 100%;
}

input,
.ant-select-single .ant-select-selector .ant-select-selection-item {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: bold !important;
    height: 44px !important;
    line-height: 44px !important;
}

.defaultValue {
    background-color: #eff1f4;
    border-radius: 8px;
    padding: 0px 11px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    height: 44px;
    line-height: 44px;
}

.ant-input-affix-wrapper {
    padding: 0 11px !important;
}

.ant-input {
    border: none !important;
    background-color: #eff1f4 !important;
    border-radius: 8px !important;
    line-height: 44px !important;
}

.ant-picker,
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    background-color: #eff1f4 !important;
    border-radius: 8px !important;
    height: 44px !important;
    line-height: 44px !important;
}

.xflow-canvas-container input,
.xflow-canvas-container .ant-input-affix-wrapper,
.xflow-canvas-container .ant-select-selector,
.xflow-canvas-container .ant-select-single .ant-select-selector .ant-select-selection-item {
    background-color: #fff !important;
    line-height: 1.5715 !important;
    height: auto !important;

}

.xflow-canvas-container .group .ant-input {
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
}

.xflow-canvas-container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
    line-height: 1.5715 !important;
    height: 24px !important;

}

.flowchart-editor-panel-body .flowchart-editor-panel-group input,
.flowchart-editor-panel-body .flowchart-editor-panel-group select {
    height: 22px !important;
}

.ant-pagination-options .ant-pagination-options-size-changer.ant-select .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    background-color: #fff !important;
    border-radius: 2px !important;
    height: 32px !important;
    line-height: 1.5715 !important;
}

.ant-pagination-options .ant-pagination-options-size-changer.ant-select input,
.ant-pagination-options .ant-pagination-options-size-changer.ant-select .ant-select-selection-item {
    height: 30px !important;
    line-height: 30px !important;
    font-weight: normal !important;
}

.cost_list textarea.ant-input {
    font-size: 16px !important;
    color: #333333 !important;
    line-height: 2 !important;
    background-color: #FFFFFF !important;
    font-weight: 500 !important;
}

.reportList textarea.ant-input {
    margin-bottom: 0 !important;
}

textarea.ant-input {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: bold !important;
    line-height: 2 !important;
}

.ant-form-item-control-input {
    align-items: flex-start !important;
}

.ant-form-item-label>label {
    height: 44px !important;
    line-height: 44px !important;
    font-size: 16px !important;
    color: #666666 !important;
}

.ant-radio-group>label,
.ant-checkbox-group>label {
    height: 44px !important;
    line-height: 44px !important;
}

.ant-checkbox-group>label {
    width: 25%;
    margin: 0 !important;
}

.ant-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.ant-form-item-label {
    /* width: 13%; */
    margin-right: 1%;
}

.all .ant-form-item-label {
    width: 5%;
    margin-right: 1%;
}

.standard .ant-form-item-label {
    width: 13%;
    margin-right: 1%;
}

.standard .ant-form-item-control {
    width: 86%;
}

.moiety .ant-form-item-label {
    width: 26%;
    margin-right: 2%;
}

.moiety .ant-form-item-control {
    width: 72%;
}

.quarter .ant-form-item-label {
    width: 45%;
    margin-right: 2%;
}

.quarter .ant-form-item-control {
    width: 56%;
}

.searchForm .ant-form-item-label>label {
    width: 100%;
    text-align: left;
}

.searchForm .ant-form-item-control input,
.searchForm .ant-form-item-control .ant-picker,
.searchForm .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #FFFFFF !important;
}

.ant-upload img {
    width: 100%;
}

.state_button {
    width: 72px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
}

.process_icon_01 {
    background-color: #FFEAD9;
    border-radius: 8px;
    border: #EF760C solid 1px;
    color: #EF760C
}

.process_icon_02 {
    background-color: #DAF7F9;
    border-radius: 8px;
    border: #2ABCC7 solid 1px;
    color: #2ABCC7
}

.process_icon_03 {
    background-color: #FFECEC;
    border-radius: 8px;
    border: #FF5757 solid 1px;
    color: #FF5757
}

.process_icon_04 {
    background-color: #FEF6E1;
    border-radius: 8px;
    border: #EFA30A solid 1px;
    color: #EFA30A
}

.process_icon_05 {
    background-color: #D4EBFF;
    border-radius: 8px;
    border: #339EF9 solid 1px;
    color: #339EF9
}

.modal_button {
    display: flex;
    justify-content: center;
}

.modal_button>button {
    width: 100px;
    font-size: 16px;
    color: #FFFFFF;
    height: 44px;
    margin: 0 15px;
    border: none;
    border-radius: 8PX;
}

.template .ant-modal-body {
    padding: 20px !important;
}

.template .ant-modal-body>div {
    width: 100%;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background-color: #339EF9;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 10px;
}

@media only screen and (max-width:2400px) {

    .ant-tree-title {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px !important;
    }

    /* login.js */
    .login_plate {
        width: 100vw;
        height: 100vh;
        background-color: #F2F6FC;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login_plate>div {
        width: 68%;
        height: 62%;
    }

    .login_plate>div>div:nth-of-type(1) {
        margin-bottom: 20PX;
    }

    .login_plate>div>div:nth-of-type(1)>img {
        width: 14%;
    }

    .login_plate>div>div:nth-of-type(2) {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
    }

    .login_plate>div>div:nth-of-type(2)>div:nth-of-type(1) {
        width: 54%;
        position: relative;
    }

    .login_plate_content {
        position: absolute;
        width: 50%;
        left: 28%;
        top: 26%;
    }

    .login_plate_content>input {
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .login_plate_content>button {
        width: 100%;
        border-radius: 8px;
        margin-top: 12px;
        box-shadow: 0px 3px 12px #339EF9;
    }

    .login_plate>div>div:nth-of-type(2)>div:nth-of-type(2)>img {
        height: 100%;
    }


    /*login*/

    .login {
        width: 100vw;
        height: 100vh;
        background-image: url(../img/background_01.png);
        background-size: 100% auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login>div {
        width: 65%;
        height: 82%;
        background-image: url(../img/background_02.png);
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login>div>div {
        width: 85%;
        height: 80%;
        display: flex;
        align-items: center;
    }

    .login>div>div>div:nth-of-type(1) {
        width: 50%;
        height: 100%;
        background-color: #1890FF;
        border-radius: 0 0 0 52px;
        padding: 8% 6% 4%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .login>div>div>div:nth-of-type(1)>div>img {
        max-width: 100%;
        margin-bottom: 10%;
    }

    .login>div>div>div:nth-of-type(1)>img {
        max-width: 100%;
    }

    .login>div>div>div:nth-of-type(2) {
        width: 50%;
        height: 100%;
        background-color: #FFFFFF;
        border-radius: 0 52px 0 0;
        padding: 9% 6%;
        color: #999999;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .login>div>div>div:nth-of-type(2)>div:nth-of-type(1) {
        display: flex;
        align-items: center;
    }

    .login>div>div>div:nth-of-type(2)>div:nth-of-type(1)>img:nth-of-type(1) {
        max-width: 25%;
    }

    .login>div>div>div:nth-of-type(2)>div:nth-of-type(1)>img:nth-of-type(2) {
        max-width: 75%;
    }


    .login>div>div>div:nth-of-type(2)>div:nth-of-type(2)>div {
        margin-bottom: 20px;
    }

    .login>div>div>div:nth-of-type(2)>div:nth-of-type(2)>div>div {
        margin-bottom: 10px;
    }

    .login>div>div>div:nth-of-type(2)>div:nth-of-type(2)>div>.ant-input,
    .login>div>div>div:nth-of-type(2)>div:nth-of-type(2)>div .ant-input-affix-wrapper {
        width: 100% !important;
        height: 52px !important;
        line-height: 52px !important;
        border-radius: 4px !important;
    }

    .login>div>div>div:nth-of-type(2)>button {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        font-size: 18px;
    }

    /* .login  .ant-input-affix-wrapper{
        background-color: ;
    } */

    /*configAdd.js*/
    .configAdd {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 20px 40px;
        min-height: 100vh;
        overflow: hidden;
    }

    .formTitle {
        width: 100%;
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin: 20px 0 50px;
        text-align: center;
    }

    .FirstTitle,
    .secondTitle {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .select_button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .searchOutlined {
        width: 28%;
        height: 44px;
        background-color: #eff1f4;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .searchOutlined>div {
        color: #339EF9;
        font-size: 16px;
        margin-left: 10px;
    }

    .FirstTitle>div,
    .secondTitle>div {
        display: flex;
        align-items: center;
        background-color: #339EF9;
        border-radius: 24px;
        height: 44px;
        padding: 0 20px;
        color: #FFFFFF;
        font-size: 16PX;
        font-weight: bold;
    }

    .select_button>div {
        display: flex;
        align-items: center;
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        border-radius: 8px;
        height: 44px;
        padding: 0 20px;
        color: #339EF9;
        font-size: 16PX;
        font-weight: bold;
        cursor: pointer;
    }

    .select_button>div>span {
        color: #F53500;
    }

    .FirstTitle>div>div:nth-of-type(1),
    .secondTitle>div>div:nth-of-type(1) {
        margin-right: 10px;
    }

    .fileList {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .fileList>span {
        width: 38%;
    }

    .fileList>div {
        width: 60%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .fileList>div>div {
        width: 48%;
        height: 52px;
        border: #00D0CC solid 1px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .searchModal .fileList>div>div {
        width: 100% !important;
        height: 44px;
    }

    .fileList>div>div>div:nth-of-type(1) {
        width: 4%;
        height: 100%;
        background-color: #00D0CC;
        border-radius: 8px 0 0 8px;
    }

    .fileList>div>div>div:nth-of-type(2) {
        width: 92%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .fileList>div>div>div:nth-of-type(2)>div:nth-of-type(1) {
        width: 84%;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }

    .fileList>div>div>div:nth-of-type(2)>div:nth-of-type(2) {
        margin-right: 10px;
    }

    .uploadList {
        position: relative;
    }

    .uploadList>div:nth-of-type(1) {
        display: flex;
        flex-wrap: wrap;
    }

    .uploadList>div:nth-of-type(1)>div {
        width: 32%;
        height: 86px;
        border: #00D0CC solid 1px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-right: 1.2%;
    }

    .searchModal .uploadList>div:nth-of-type(1)>div {
        width: 48%;
    }

    .uploadList>div:nth-of-type(1)>div>div:nth-of-type(1) {
        width: 4%;
        height: 100%;
        background-color: #00D0CC;
        border-radius: 8px 0 0 8px;
    }

    .uploadList>div:nth-of-type(1)>div>div:nth-of-type(2) {
        width: 90%;
        margin-right: 2%;
    }

    .uploadList>div:nth-of-type(1)>div>div:nth-of-type(2)>div:nth-of-type(1) {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }

    .uploadList>div:nth-of-type(1)>div>div:nth-of-type(2)>div:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .uploadList>div:nth-of-type(1)>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {
        font-size: 14px;
        color: #999999;
    }

    .batch {
        position: absolute;
        top: 42px;
        left: -136px;
        display: flex;
        align-items: center;
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        border-radius: 8px;
        padding: 5px 12px;
        font-size: 16px;
        color: #339EF9;
        font-weight: bold;
    }

    .processState>div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .processState>div:nth-of-type(1) {
        width: 24%;
    }

    /* .processState>div:nth-of-type(1)>div:nth-of-type(1) {
        width: 64%;
    } */

    .processState>div:nth-of-type(1)>div:nth-of-type(2) {
        width: 28%;
    }

    /* .processState>div:nth-of-type(2),
    .processState>div:nth-of-type(3) {
        width: 40%;
    } */

    .processState>div>div:nth-of-type(1) {
        width: 24%;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }

    /* .processState>div>input {
        width: 72%;
    } */

    .processSteps {
        margin: 30px 0 40px;
        width: 100%;
        height: 350px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .processSteps>div {
        width: 30%;
        display: inline-block;
        /* margin-right: 2%; */
    }

    .processSteps>div>div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .processSteps>div>div>div {
        width: 80%;
    }

    .processSteps>div>div>img {
        width: 24px;
    }

    .processSteps>div>div>div>div:nth-of-type(1) {
        background-color: #339EF9;
        border-radius: 16px 16px 0 0;
        padding: 16px 0;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
    }

    .processSteps>div>div>div>div:nth-of-type(2) {
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        padding: 16px 15px;
        font-size: 16px;
    }

    .processSteps>div>div>div>div:nth-of-type(2)>div:nth-of-type(1) {
        margin-right: 10px;
        color: #666666;
    }

    .processSteps>div>div>div>div:nth-of-type(3) {
        background-color: #eff1f4;
        padding: 20px 15px;
        font-size: 16px;
        border-radius: 0 0 16px 16px;
    }

    .processSteps>div>div>div>div:nth-of-type(3)>div {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }

    .processSteps>div>div>div>div:nth-of-type(3)>div>div:nth-of-type(1) {
        color: #666666;
        font-size: 16px;
    }

    .processSteps>div>div>div>div:nth-of-type(3)>div>div:nth-of-type(2) {
        font-weight: bold;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }

    .makeCopy>div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-size: 16px;
        margin-bottom: 40px;
    }

    .makeCopy>div:nth-of-type(1)>div {
        display: flex;
        align-items: center;
    }

    .makeCopy>div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(2) {
        display: flex;
        margin-left: 32px;
    }

    .makeCopy>div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(2)>div {
        background-color: #339EF9;
        border: #FFFFFF solid 2px;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        position: relative;
        color: #FFFFFF;
        font-size: 16PX;
        font-weight: bold;
        margin-left: -12px;
    }

    .makeCopy>div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(2)>div>div:nth-of-type(2) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
    }

    .makeCopy>div:nth-of-type(2) {
        width: 100%;
        margin-bottom: 40px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .makeCopy>div:nth-of-type(2)>div {
        width: 23%;
        display: inline-block;
        margin-right: 2%;
    }

    .makeCopy>div:nth-of-type(2)>div>div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px;
        background-color: #eff1f4;
        border-radius: 16px;
    }

    .makeCopy>div:nth-of-type(2)>div>div>div:nth-of-type(1) {
        background-color: #339EF9;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        color: #FFFFFF;
        font-size: 16PX;
        font-weight: bold;
    }

    .makeCopy>div:nth-of-type(2)>div>div>div:nth-of-type(2) {
        font-size: 16px;
        width: 64%;
    }

    .makeCopy>div:nth-of-type(2)>div>div>div:nth-of-type(2)>div:nth-of-type(2) {
        font-weight: bold;
        margin-top: 10px;
    }

    .makeCopy>div:nth-of-type(2)>div>div>div:nth-of-type(3) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }

    /* Home.js */

    .home_plate {
        width: 100vw;
        height: 100vh;
        background-color: #F4F5F9;
        display: flex;
        justify-content: space-between;
    }

    .sideMenu {
        flex: auto !important;
        max-width: 300px !important;
        min-width: auto !important;
        width: 300px !important;
        height: 100%;
        background: #339EF9 !important;
        border-radius: 0 30px 30px 0;
        overflow: auto;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }


    .sideMenu .ant-menu-inline {
        width: 98%;
        margin-left: 2%;
        border-right: none
    }

    .sideMenu .ant-menu-inline div,
    .sideMenu .ant-menu-inline li {
        padding: 0 !important;
    }

    .sideMenu>.ant-layout-sider-children>ul>.ant-menu-item,
    .sideMenu>.ant-layout-sider-children>ul>li>.ant-menu-submenu-title {
        padding-left: 3% !important;
    }

    .sideMenu>.ant-layout-sider-children>ul>li ul>li {
        padding-left: 5% !important;
    }

    .sideMenu .ant-menu,
    .sideMenu .ant-menu-sub.ant-menu-inline {
        background: #339EF9;
        color: #FFFFFF
    }

    .sideMenu .ant-menu-submenu-selected {
        color: #FFFFFF
    }

    .sideMenu .ant-menu-submenu-expand-icon,
    .sideMenu .ant-menu-submenu-arrow {
        color: #FFFFFF
    }

    .sideMenu>div>ul>li {
        font-size: 20px;
        font-weight: bold;
    }

    .sideMenu>div>ul>li>div .ant-menu-item-icon,
    .sideMenu>div>ul>li .ant-menu-item-icon {
        font-size: 20px;
    }

    .sideMenu>div>ul>li>ul>li .ant-menu-item-icon {
        font-size: 16px;
    }

    .sideMenu>div>ul>li ul>li {
        font-size: 16px;
        font-weight: bold;
    }

    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
        color: #aed8ff !important
    }

    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
        color: #aed8ff !important
    }

    .sideMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #FFFFFF;
        color: #339EF9;
        position: relative;
        border-radius: 20px 0 0 20px;
    }

    .sideMenu .ant-menu-inline .ant-menu-item {
        overflow: visible;
        margin: 10px 0;
    }

    .sideMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::before,
    .sideMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        right: 1px;
        background: radial-gradient(20px at 20px 0px, #339EF9 20px, #FFFFFF 20px);
    }

    .sideMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::before {
        bottom: -20px;
        transform: scale(-1);
    }

    .sideMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after {
        top: -20px;
        transform: scaleX(-1)
    }

    .sideMenu .ant-menu-inline .ant-menu-item::after {
        border-right: none;
    }

    .sideMenu_plate {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sideMenu_plate>img {
        margin: 35px 0 30px;
    }

    .sideMenu_plate>div {
        background-color: #FFFFFF;
        border-radius: 8px;
        height: 44px;
        margin-bottom: 30px;
    }

    .sideMenu_plate>div .ant-btn {
        background: none;
        border: none;
        box-shadow: none
    }

    .sideMenu_plate>div .ant-input-group-addon {
        background: none;
    }

    .sideMenu_plate>div .ant-btn-icon-only {
        width: auto;
        margin-right: 10px;
    }

    .sideMenu_plate .ant-input {
        background-color: #FFFFFF !important;
    }

    .sideMenu_plate .ant-input:focus {
        border: none;
        border-bottom: none;
        box-shadow: none;
    }

    .sandbox {
        width: calc(100vw - 300px);
        height: 100%;
        padding: 40px;
        padding-bottom: 0 !important;
    }

    .home_plate_right {
        width: 100%;
        height: 100%;
    }

    .TopHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
    }

    .TopHeader>div:nth-of-type(1) {
        display: flex;
        align-items: center;
        width: calc(100vw - 580px);
    }

    .desktop {
        background-color: #339EF9;
        padding: 12px 30px;
        color: #FFFFFF;
        border-radius: 16px;
    }

    .labelList {
        display: flex;
        align-items: center;
    }

    .labelList>div:nth-of-type(1) {
        padding: 12px 30px;
        color: #999999;
        background-color: #FFFFFF;
        border-radius: 16px 0 0 16px;
        cursor: pointer;
    }

    .final_menu {
        border-radius: 0 16px 16px 0;
    }

    .current_menu {
        background-color: #339EF9 !important;
        color: #FFFFFF !important
    }

    .labelList>div:nth-of-type(2) {
        width: calc(100vw - 720px);
        ;
        display: flex;
        align-items: center;
    }

    .labelList>div:nth-of-type(2)>div {
        width: 20%;
        display: flex;
        align-items: center;
        color: #999999;
        background-color: #FFFFFF;
        padding: 12px 30px;
        cursor: pointer;
    }

    .labelList>div:nth-of-type(2)>div>div:nth-of-type(1) {
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }

    .labelList>div:nth-of-type(2)>div>div:nth-of-type(2) {
        margin-left: 20px;
    }

    .labelReturn {
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        padding: 12px 30px;
        color: #999999;
        border-radius: 16px;
        margin-left: 30px;
    }

    .labelReturn>div:nth-of-type(2) {
        margin-left: 12px;
    }

    .home_plate_right_dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .home_plate_right_dropdown>div {
        margin-left: 20px;
    }

    .home_plate_content {
        max-height: calc(100vh - 120px);
        overflow-y: auto;
    }

    /* Index.js */

    .index_introduce {
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 30px;
    }

    .index_introduce>div:nth-of-type(1) {
        width: 38%;
        display: flex;
        align-items: center;
    }

    .index_introduce>div:nth-of-type(1)>div:nth-of-type(1) {
        margin-left: 30px;
    }

    .index_introduce>div:nth-of-type(1)>div:nth-of-type(2) {
        margin-left: 20px;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
    }

    .index_introduce>div:nth-of-type(2) {
        width: 62%;
    }

    .index_introduce>div:nth-of-type(2)>img {
        width: 100%;
    }

    .index_style_02 {
        margin: 30px 0;
    }

    .index_style_01>div:nth-of-type(1),
    .index_style_02>div:nth-of-type(1) {
        background-color: #FFEFDB;
        padding: 12px 30px;
        border-radius: 16px 16px 0 0;
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
    }

    .index_style_01>div:nth-of-type(2) {
        background-color: #FFFFFF;
        padding: 18px 30px;
        border-radius: 0 0 16px 16px;
    }

    .index_notice>div {
        display: flex;
        justify-content: space-between;
    }

    .index_notice>div:nth-of-type(1) {
        margin-bottom: 8px;
    }

    .index_flow {
        display: flex;
        justify-content: space-between;
    }

    .index_flow>div {
        width: 32%;
        padding: 10px 50px 30px;
        background-color: #FFFFFF;
        cursor: pointer;
    }

    .index_flow>div>div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 40px;
    }

    .flow_backlog>div:nth-of-type(1) {
        display: flex;
        align-items: center;
    }

    .flow_backlog>div:nth-of-type(1)>div:nth-of-type(1) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #FFFFFF;
    }

    .flow_backlog>div:nth-of-type(1)>div:nth-of-type(2) {
        font-size: 60px;
        font-weight: bold;
        color: #333333;
        margin-left: 10px;
    }

    .flow_backlog>div:nth-of-type(2)>div:nth-of-type(1) {
        font-size: 16px;
        color: #339EF9;
        margin-bottom: 5px;
    }

    .flow_backlog>div:nth-of-type(2) {
        margin-left: 26px;
    }

    .flow_backlog>div:nth-of-type(2)>div:nth-of-type(2) {
        width: 20px;
        height: 4px;
    }

    .flow_backlog_state_01 {
        background-color: #FFA81C;
    }

    .flow_backlog_state_02 {
        background-color: #00DC9E;
    }

    .flow_backlog_state_03 {
        background-color: #315CF3;
    }

    .flow_backlog_state_04 {
        background-color: #FF5E00;
    }

    .clear {
        width: 40px;
        height: 40px;
        margin-left: 20px;
    }

    .index_flow>div>div:nth-of-type(2) {
        font-size: 22px;
        color: #333333;
        margin-left: 26px;
    }

    .index_flow>div:nth-of-type(1) {
        border-radius: 0 0 0 16px;
    }

    .index_flow>div:nth-of-type(3) {
        border-radius: 0 0 16px 0;
    }

    .common_function {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 30px !important;
    }

    .my_process {
        display: flex;
        align-items: center;
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        border-radius: 8px;
        padding: 8px 12px;
        font-size: 16px;
        color: #339EF9;
        font-weight: bold;
    }

    .my_process>div {
        margin-left: 5px !important;
    }

    .common_function>div:nth-of-type(2) {
        display: flex;
        align-items: center;
    }

    .common_function>div:nth-of-type(2)>div {
        margin-left: 20px;
    }

    .index_function {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .index_function>div {
        width: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .index_function>div>div:nth-of-type(1) {
        width: 32px;
        margin-bottom: 6px;
        position: relative;
    }

    .index_function>div>div:nth-of-type(1)>img:nth-of-type(2) {
        position: absolute;
        top: -8px;
        right: -8px;
    }


    .index_function>div>div:nth-of-type(2) {
        width: 92%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    /* 常用功能编辑弹框 */

    .userModal .ant-modal {
        width: 420px !important;
        border-radius: 16px;
    }

    .linkModal .ant-modal {
        width: 720px !important;
    }

    .linkModal .ant-modal-body {
        padding: 20px !important;
    }

    .linkModal .link>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .linkModal .link>div>div:nth-of-type(1) {
        width: 22%;
        border-radius: 14px;
        color: #666666;
        text-align: right;
    }

    .linkModal .link>div>div:nth-of-type(2) {
        background-color: #F8F9FB;
        border-radius: 8px;
        width: 64%;
        padding: 0 10px;
        height: 44px;
        line-height: 44px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    .summaryModal .ant-modal {
        width: 58% !important;
        border-radius: 16px;
    }

    .summaryModal .ant-modal .ant-modal-body {
        padding: 24px !important;
    }

    .summaryModal .ant-modal .ant-modal-body>div>div:nth-of-type(1) {
        font-size: 22px;
        text-align: center;
        color: #333333;
        margin-bottom: 20px;
    }

    .summaryModal .ant-modal .ant-modal-body>div>div:nth-of-type(2) {
        margin-bottom: 20px;
    }

    .summaryModal .ant-modal .ant-modal-body>div>div:nth-of-type(3) {
        display: flex;
        justify-content: center;
    }

    .summaryModal .ant-modal .ant-modal-body>div>div:nth-of-type(3)>div {
        width: 100px;
        text-align: center;
        font-size: 16px;
        height: 44px;
        line-height: 44px;
        color: #FFFFFF;
        border-radius: 8px;
        background-color: #339EF9;
        cursor: pointer;
    }

    .ant-modal-content {
        border-radius: 16px !important;
    }

    .ant-modal-header {
        padding: 10px 20px !important;
        color: #333333 !important;
        background: #eff1f4 !important;
        border-bottom: none !important;
        border-radius: 16px 16px 0 0 !important;
    }

    .ant-modal-title {
        color: #333333 !important;
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .ant-modal-close {
        color: #333333 !important;
        right: 5px !important;
    }

    .ant-modal-close-x {
        width: 42px !important;
        height: 42px !important;
        line-height: 42px !important;
    }

    .ant-modal-footer {
        padding: 0px 48px 30px !important;
        border-top: none !important;
    }

    .ant-modal-footer .ant-btn {
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 30px;
        height: auto;
    }

    .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 20px !important;
    }

    .ant-modal-body {
        padding: 30px 48px 20px !important;
        font-size: 16px !important;
        color: #333333;
        line-height: 1 !important;
    }

    .initialValue .ant-modal {
        width: 64% !important;
        border-radius: 16px;
    }

    .initialValue .ant-modal-body {
        padding: 20px 0px !important;
    }

    .initialValue .ant-modal-footer {
        padding: 0px !important;
    }

    .initialValue .filter {
        padding: 0 20px;

    }

    /* .initialValue .ant-form-item-label {
    width: 15% !important;
    margin-right: 2%;
} */

    .searchModal .ant-modal {
        width: 54% !important;
        border-radius: 16px;
    }

    .searchModal .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }

    .searchModal .ant-modal-footer {
        text-align: center;
    }

    .handleAppend {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .handleAppend>button {
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 30px;
        height: auto;
        color: #fff;
        border-color: #1890ff;
        background: #1890ff;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    }

    /* 待办待阅 */
    .condition>div {
        display: flex;
        align-items: center;
    }

    .condition>div>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .condition>div:nth-of-type(1) {
        justify-content: space-between;
    }

    .condition>div:nth-of-type(1)>div>div:nth-of-type(1) {
        font-size: 16px;
        color: #333333;
    }

    .conditionBacklog>div,
    .conditionFlow>div {
        width: 49%;
    }

    .conditionBacklog>div>div:nth-of-type(1),
    .conditionFlow>div>div:nth-of-type(1) {
        width: 15%;
    }

    .conditionBacklog>div>div:nth-of-type(2),
    .conditionFlow>div>div:nth-of-type(2) {
        width: 85%;
    }

    .conditionWeekly>div {
        width: 32%;
    }

    .conditionWeekly>div>div:nth-of-type(1) {
        width: 10%;
    }

    .conditionWeekly>div>div:nth-of-type(2) {
        width: 90%;
    }

    .condition>div:nth-of-type(2) {
        justify-content: center;
        margin-bottom: 20px;
    }

    .condition>div:nth-of-type(2)>div {
        height: 44px;
        padding: 0px 20px;
        margin: 0 15px;
        background-color: #FFFFFF;
        border-radius: 8px;
        font-size: 16px;
        color: #333333;
    }

    .condition>div:nth-of-type(2)>div>div:nth-of-type(1) {
        margin-right: 12px;
    }

    .condition .ant-input,
    .condition .ant-picker {
        background-color: #FFFFFF !important;
    }

    .timeStyle>div {
        display: flex;
        align-items: center;
    }

    .timeStyle>div:nth-of-type(1)>div:nth-of-type(1) {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #00DC9E;
        margin-right: 8px;
    }

    .timeStyle>div:nth-of-type(2)>div:nth-of-type(1) {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #F53500;
        margin-right: 8px;
    }

    /* 审核状态 */
    .process-state {
        display: flex;
    }

    .process-state>div {
        padding: 6px 12px;
        font-size: 16px;
    }

    .green {
        color: #2ABCC7;
        background-color: #DAF7F9;
        border: 1px solid #2ABCC7;
        border-radius: 8px;
    }

    .audit {
        color: #EF760C;
        background-color: #FFEAD9;
        border: 1px solid #EF760C;
        border-radius: 8px;
    }

    .complete {
        color: #339EF9;
        background-color: #D4EBFF;
        border: 1px solid #339EF9;
        border-radius: 8px;
    }

    .rejected {
        color: #FF5757;
        background-color: #FFECEC;
        border: 1px solid #FF5757;
        border-radius: 8px;
    }

    .termination {
        color: #EFA30A;
        background-color: #FEF6E1;
        border: 1px solid #EFA30A;
        border-radius: 8px;
    }

    .ant-table-filter-trigger {
        margin-right: 10px !important;
    }

    .filterDropdown>div {
        font-size: 16px;
        padding: 8px 16px;
        cursor: pointer;
    }

    .filterDropdown>div:hover {
        background-color: #eff1f4;
    }

    /* 列表组件功能样式 */

    .ListSearch {
        width: 100%;
    }

    .ListSearch .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
    }

    .ListOperate,
    .TableOperation,
    .TableProcess {
        /* width: 100%; */
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .ListSearch .ant-form-item-control-input-content button,
    .ListOperate button,
    .TableOperation>button,
    .TableProcess>button {
        /* width: 100px; */
        padding: 0 24px;
        font-size: 16px;
        color: #333333;
        height: 44px;
        line-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;
        border: none;
    }

    .BasicData button {
        background: #339EF9 !important;
        color: #FFFFFF !important;
    }

    .ListSearch .ant-form-item-control-input-content button {
        border-radius: 8px;
    }

    .ListOperate button {
        border-radius: 24px;
        font-weight: bold;
    }

    .TableOperation>button {
        border-radius: 24px;
        font-weight: bold;
        background-color: #eff1f4 !important;
    }

    .TableProcess>button {
        color: #FFFFFF;
        border-radius: 24px;
        font-weight: bold;
    }

    .ListSearch .ant-form-item-control-input-content button>span {
        margin-right: 12px;
    }

    .ListOperate>button>div,
    .TableOperation>button>div {
        margin: 0 6px;
    }

    .download {
        width: 62px;
        height: 36px;
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        color: #339EF9;
        font-size: 16px;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
    }

    /* Hetongshenpi.js */

    .contract_List {
        display: flex;
        justify-content: space-between;
    }

    .contract_List>div:nth-of-type(1) {
        width: 380px;
        padding: 20px 10px;
        min-height: calc(100vh - 150px);
        background-color: #FFFFFF;
        border-radius: 16px;
    }

    .contract_List>div:nth-of-type(2) {
        width: calc(100vw - 810px)
    }

    .menuList input {
        height: 0 !important;
    }

    .menuList .ant-tree {
        font-size: 16px;
        height: calc(100vh - 240px);
        overflow:auto
    }

    .menuList .ant-tree-switcher,
    .menuList .ant-tree .ant-tree-node-content-wrapper {
        display: flex;
        line-height: 32px;
    }

    .menuList .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .ant-tree-show-line .ant-tree-switcher-line-icon {
        line-height: 32px;
    }

    /*lingxingyonggong.js */
    .labor_search {
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    /* .labor_search>div {
        width: 50%;
    } */

    .screen_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .screen_right>div,
    .summarySearch {
        /* width: 40%; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 5%;
    }

    .screen_right>div>div:nth-of-type(1) {
        /* width: 30%; */
        width: 72px;
    }

    .screen_right .ant-select {
        /* width: 70%; */
        width: 120px;
    }

    .screen_right .ant-select .ant-select-selector {
        background-color: #FFFFFF !important;
        box-shadow: none !important;
    }

    .summarySearch>div:nth-of-type(1) {
        /* width: 30%; */
        width: 72px;
    }

    .summarySearch .ant-select {
        /* width: 70%; */
        width: 300px;
    }

    .summarySearch .ant-select .ant-select-selector {
        background-color: #FFFFFF !important;
        box-shadow: none !important;
    }


    /*renjiashenpi.js */

    .supplier_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .supplier_left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .supplier_right>div {
        /* width: 72px; */
        /* text-align: center; */
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        height: 44px;
        line-height: 44px;
        background-color: #FFFFFF;
        cursor: pointer;
    }

    .supplier_left>div {
        /* width: 100px;
        text-align: center; */
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        height: 44px;
        line-height: 44px;
        background-color: #FFFFFF;
        cursor: pointer;
    }

    .supplier_right>div:nth-of-type(1),
    .supplier_left>div:nth-of-type(1) {
        border-radius: 24px 0 0 24px;
    }

    .supplier_right>div:nth-of-type(3),
    .supplier_left>div:nth-of-type(3) {
        border-radius: 0 24px 24px 0;
    }

    .supplier_style>div:nth-of-type(3) {
        border-radius: 0;
    }

    .supplier_style>div:nth-of-type(8) {
        border-radius: 0 24px 24px 0;
    }

    .design>div:nth-of-type(3),
    .designs>div:nth-of-type(3),
    .designes>div:nth-of-type(3) {
        border-radius: 0 !important;
    }

    .designes>div:nth-of-type(4) {
        border-radius: 0 24px 24px 0;
    }

    .design>div:nth-of-type(5) {
        border-radius: 0 24px 24px 0;
    }

    .designs>div:nth-of-type(6) {
        border-radius: 0 24px 24px 0;
    }

    /* .designes>div:nth-of-type(6) {
    border-radius: 0 24px 24px 0;
} */

    .employeesType>div:nth-of-type(2) {
        border-radius: 0 24px 24px 0;
    }

    .supplier_selection {
        background-color: #339EF9 !important;
        color: #FFFFFF !important;
    }

    /*chengbenyusuan.js*/
    .cost_budgeting {
        display: flex;
        align-items: center;
    }

    .history>button:nth-of-type(1) {
        width: 120PX;
        background-color: #00D0CC;
        color: #FFFFFF;
    }

    .history>div {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        margin: 0 10px;
    }

    .history>div .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 100px;
        background-color: #FFFFFF !important;
        box-shadow: none !important;
        margin-left: 16px;
    }

    .drop_down_options {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .drop_down_options>div {
        margin: 0 6px;
    }

    .schedule {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .schedule>div {
        width: 32%;
        padding: 20px 40px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
    }

    .select_month {
        background-color: #F2FAFF;
    }

    .select_month>div:nth-of-type(1)>div:nth-of-type(1) {
        font-size: 48px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 18px;
    }

    .select_month>div:nth-of-type(1)>div:nth-of-type(2) {
        font-size: 16px;
        font-weight: bold;
        color: #339EF9;
        margin-bottom: 35px;
    }

    .select_month>div:nth-of-type(1)>div:nth-of-type(2)>span:nth-of-type(1) {
        margin-right: 16px;
    }

    .select_month>div:nth-of-type(2) {
        position: relative;
    }

    .select_month>div:nth-of-type(2)>div {
        position: absolute;
        font-size: 30px;
        color: #FFFFFF;
        right: 12px;
        bottom: 0px;
    }

    .uncheck_month {
        background-color: #FFFFFF;
    }

    .uncheck_month>div:nth-of-type(1)>div:nth-of-type(1) {
        font-size: 48px;
        font-weight: bold;
        color: #7C8DB5;
        margin-bottom: 18px;
    }

    .uncheck_month>div:nth-of-type(1)>div:nth-of-type(2) {
        font-size: 16px;
        font-weight: bold;
        color: #7C8DB5;
        margin-bottom: 35px;
    }

    .uncheck_month>div:nth-of-type(1)>div:nth-of-type(2)>span:nth-of-type(1) {
        margin-right: 16px;
    }

    .uncheck_month>div:nth-of-type(2) {
        position: relative;
    }

    .uncheck_month>div:nth-of-type(2)>div {
        position: absolute;
        font-size: 30px;
        color: #FFFFFF;
        right: 12px;
        bottom: 0px;
    }

    .total_prices {
        text-align: center;
    }

    .total_price {
        text-align: left !important;
    }

    .total_prices>span:nth-of-type(1),
    .total_prices>span:nth-of-type(3) {
        margin-left: 80px;
    }

    .total_prices>span:nth-of-type(2),
    .total_prices>span:nth-of-type(4) {
        margin-left: 40px;
        color: #339EF9;
        font-size: 28px;
    }


    /* 认价对比筛选*/
    .contrastNew .ant-modal-body>div:nth-of-type(2) {
        margin-bottom: 20px;
    }

    .contrastNew .ant-modal-body>div:nth-of-type(2)>div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .contrastNew .ant-modal-body>div:nth-of-type(2)>div:nth-of-type(1)>div {
        width: 13%;
    }

    .contrastNew .ant-modal-body>div:nth-of-type(5)>div:nth-of-type(1) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .contrastNew .ant-modal-body>div:nth-of-type(5)>div:nth-of-type(1)>div {
        height: 38px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        border: rgba(0, 0, 0, 12%) solid 1px;
        border-radius: 8px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .contrastNew .ant-modal-body>div:nth-of-type(5)>div:nth-of-type(1)>div>div {
        margin-right: 20px;
    }

    .appoint_operate {
        display: flex;
        align-items: center;
    }

    .appoint_operate>div {
        padding: 0 5px;
        font-size: 16px;
        color: #339EF9;
        cursor: pointer;
    }

    .weekly .ant-modal {
        width: 625px !important;
    }

    .weeklys .ant-modal {
        width: 64% !important;
    }

    .weekly .ant-modal-body {
        padding: 24px 12px 0px !important;
    }

    .weekly .ant-modal-footer {
        text-align: center;
    }

    .weekly_time {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .weekly_time>div {
        position: relative;
        width: 100px;
        height: 78px;
        background-color: #339EF9;
        border-radius: 8px;
        margin-bottom: 20px;
        margin: 0 10px 24px;
    }

    .weekly_time>div>img {
        width: 42px;
        height: 42px;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .weekly_time>div>div {
        padding: 12px;
        color: #FFFFFF;
    }

    .weekly_time>div>div>div:nth-of-type(1) {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .weekly_time>div>div>div:nth-of-type(2) {
        font-size: 16px;
        font-weight: bold;
    }

    .nameList {
        display: flex;
        align-items: center;

    }

    .nameList>div {
        padding: 0 20px;
        background-color: #eff1f4;
        font-size: 16px;
        color: #333333;
        border-radius: 2px;
        height: 44px;
        line-height: 44px;
        margin-right: 20px;
    }

    .cart_font {
        width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }

    .att_value {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .att_value>div:nth-of-type(1) {
        font-size: 24px;
        color: #333333;
        margin-bottom: 20px;
    }

    .att_value>div:nth-of-type(2) {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .att_value>div:nth-of-type(2)>div {
        width: 120px;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
        text-align: center;
        line-height: 80px;
        margin: 0 5px;
        cursor: pointer;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(1) {
        background-color: #FFEAD9;
        border: #EF760C solid 1px;
        color: #EF760C;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(2) {
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        color: #339EF9;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(3) {
        background-color: #DAF7F9;
        border: #2ABCC7 solid 1px;
        color: #2ABCC7;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(4) {
        background-color: #FEF6E1;
        border: #EFA30A solid 1px;
        color: #EFA30A;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(5) {
        background-color: #FFECEC;
        border: #FF5757 solid 1px;
        color: #FF5757;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(7) {
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        color: #339EF9;
    }

    .att_value>div:nth-of-type(2)>div:nth-of-type(6) {
        background-color: #DAF7F9;
        border: #2ABCC7 solid 1px;
        color: #2ABCC7;
    }

    .parameter {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
    }

    .parameter>div:nth-of-type(1) {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
    }

    .parameter .option {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .parameter .option .ant-select,
    .parameter .option .ant-input {
        width: 340px;
        margin-left: 20px;
    }

    .parameter .numberValue .ant-select,
    .parameter .numberValue .ant-input {
        width: 340px;
        margin-left: 46px;
    }

    .parameter .numberValue>div {
        display: flex;
        align-items: center;
        margin: 0 10px 20px;
    }

    .parameter .deType {
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .parameter .deType>div {
        width: 112px;
        height: 64px;
        border-radius: 8px;
        font-size: 16px;
        text-align: center;
        line-height: 64px;
        margin: 0 5px;
        cursor: pointer;
    }

    .parameter .deType>div:nth-of-type(1) {
        background-color: #FFEAD9;
        border: #EF760C solid 1px;
        color: #EF760C;
    }

    .parameter .deType>div:nth-of-type(2) {
        background-color: #D4EBFF;
        border: #339EF9 solid 1px;
        color: #339EF9;
    }

    .summaryTable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .summaryTable>div {
        width: 32%;
        height: 120px;
        border-radius: 16px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #FFFFFF;
    }

    .summaryTable>div>div:nth-of-type(1) {
        text-align: left;
        font-size: 18px;
    }

    .summaryTable>div>div:nth-of-type(2) {
        text-align: right;
        font-size: 30px;
        font-weight: bold;
    }

    .summaryTable>div:nth-of-type(1) {
        background-color: #57B9EB;
    }

    .summaryTable>div:nth-of-type(2) {
        background-color: #FF9263;
    }

    .summaryTable>div:nth-of-type(3) {
        background-color: #29D1D7;
    }

    .contract_List th {
        font-size: 16px !important;
    }

    .TreeScroll {
        height: 150px;
        overflow: hidden;
        overflow-y: auto
    }

    .TreeScroll .ant-tree>div:nth-of-type(1) {
        height: 0;
    }

    .TreeScroll .ant-tree .ant-tree-node-content-wrapper {
        line-height: 30px;
    }

    .control_table {
        width: 100%;
        height: calc(100vh - 120px);
        border-radius: 16px;
        padding: 20px;
        color: #fff;
        font-size: 16px;
        background: #033c76;
        background: -webkit-radial-gradient(50% 35%, farthest-corner, #034f8e, #034987, #02366d, #002353);
        background: radial-gradient(50% 35%, farthest-corner, #034f8e, #034987, #02366d, #002353);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .control_table>div {
        display: flex;
        justify-content: space-between;
    }

    .table-top {
        height: 72%;
    }

    .table-top-left,
    .table-buttom-right {
        width: 30.8%;
        height: 100%;
    }

    .table-top-left-top {
        height: 55px;
        font-size: 24px;
        color: #daf9ff;
    }

    .table-top-left-buttom,
    .table-top-right-buttom {
        height: calc(100% - 55px);
        border: 1px solid rgba(7, 118, 181, .5);
        box-shadow: inset 0 0 10px rgb(7 118 181 / 40%);
    }

    .table-top-right,
    .table-buttom-left {
        width: 68%;
        height: 100%;
    }

    .table-top-right-top {
        height: 55px;
        font-size: 20px;
        color: #daf9ff;
        display: flex;
    }

    .table-top-right-top>div:nth-of-type(1) {
        background: #038DCE;
        height: 34px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 34px;
        margin-right: 20px;
        padding: 0px 10px;
    }

    .table-buttom {
        height: 26%;
    }

    .table-buttom-left,
    .table-buttom-right {
        border: 1px solid rgba(7, 118, 181, .5);
        box-shadow: inset 0 0 10px rgb(7 118 181 / 40%);
    }

    .con_name,
    .con_name div {
        position: relative;
    }

    .con_name div {
        height: 34px;
        padding: 0 10px;
    }

    .con_name:before,
    .con_name:after {
        position: absolute;
        width: 10px;
        height: 5px;
        opacity: .4;
        content: "";
        border-top: 2px solid #02a6b5;
        top: -1px;
        border-radius: 2px;
    }

    .con_name:before,
    .con_name div:before {
        border-left: 2px solid #02a6b5;
        left: -1px;
    }

    .con_name:after,
    .con_name div:after {
        border-right: 2px solid #02a6b5;
        right: -1px;
    }

    .con_name div:before,
    .con_name div:after {
        position: absolute;
        width: 10px;
        height: 5px;
        opacity: .4;
        content: "";
        border-bottom: 2px solid #02a6b5;
        bottom: -1px;
        border-radius: 2px;
    }

    .con_name div:hover {
        color: #f4e925;
    }

    .con_name:hover:before,
    .con_name:hover:after,
    .con_name div:hover:before,
    .con_name div:hover:after {
        border-color: #f4e925;
        opacity: 1;
    }

    .pro_overview {
        height: 50px;
        padding: 10px 10px 10px 28px;
        border-bottom: 1px solid rgba(7, 118, 181, .7);
        font-size: 18px;
        position: relative;
    }

    .pro_overview:before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background: rgba(22, 214, 255, .9);
        box-shadow: 0 0 5px rgba(22, 214, 255, .9);
        border-radius: 10px;
        left: 10px;
        top: 22px;
    }

    .pro_overview:after {
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
        box-shadow: 0 0 5px rgba(131, 189, 227, 1);
        opacity: .6
    }

    .pro_introduce {
        height: calc(100% - 50px);
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .pro_introduce>div {
        height: 11%;
        line-height: 3.2;
        border-bottom: 1px dotted#407abd;
        font-size: 16px;
        padding-left: 16px;
        display: flex;
    }

    .pro_introduce>div:nth-of-type(9) {
        border-bottom: none;
    }

    .pro_introduce>div>div:nth-of-type(1) {
        width: 84px;
    }

    .pro_introduce>div>div:nth-of-type(2) {
        width: calc(100% - 84px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    .contract_date,
    .actual_date {
        display: flex;
        align-items: center;

    }

    .contract_date>div:nth-of-type(1),
    .actual_date>div:nth-of-type(1) {
        padding-left: 15px;
        position: relative;
    }

    .contract_date>div:nth-of-type(1):before,
    .actual_date>div:nth-of-type(1):before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background: rgba(0, 220, 158, .9);
        box-shadow: 0 0 2px rgba(0, 220, 158, .9);
        border-radius: 10px;
        left: 0px;
        top: 22px;
    }

    .contract_date>div:nth-of-type(2),
    .actual_date>div:nth-of-type(2) {
        padding-left: 15px;
        position: relative;
        margin-left: 20px;
    }

    .contract_date>div:nth-of-type(2):before,
    .actual_date>div:nth-of-type(2):before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background: rgba(245, 53, 0, .9);
        box-shadow: 0 0 2px rgba(245, 53, 0, .9);
        border-radius: 10px;
        left: 0px;
        top: 22px;
    }

    .pro_cost,
    .mat_excess {
        height: calc(100% - 50px);
        padding: 0 12px;
    }

    .pro_cost>div {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        position: relative;
    }

    .pro_cost>div:nth-of-type(1) {
        border-bottom: 1px dotted#407abd;
    }

    .pro_cost>div:nth-of-type(1)>div,
    .pro_cost>div:nth-of-type(2)>div {
        width: 15%;
    }

    .pro_cost>div:nth-of-type(2) {
        font-size: 20px;
        font-weight: bold;
    }

    .pro_cost>div:nth-of-type(2)>div:nth-of-type(1) {
        color: #E4DD40;
    }

    .pro_cost>div:nth-of-type(2)>div:nth-of-type(3),
    .pro_cost>div:nth-of-type(2)>div:nth-of-type(5) {
        color: #07D7F3;
    }


    .pro_cost>div:nth-of-type(2)>div:nth-of-type(6),
    .pro_cost>div:nth-of-type(2)>div:nth-of-type(7) {
        color: #EC5355;
    }

    .pro_cost>div:nth-of-type(1)>div:nth-of-type(7),
    .pro_cost>div:nth-of-type(2)>div:nth-of-type(7) {
        width: 10%;
    }

    .mat_excess {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mat_excess>div {
        width: 50%;
        display: flex;
        align-items: center;
    }

    .mat_excess>div:nth-of-type(1)>div:nth-of-type(1) {
        width: 100px;
        height: 100px;
    }

    .mat_excess>div:nth-of-type(1)>div:nth-of-type(2)>div {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .mat_excess>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(1) {
        width: 12px;
        height: 12px;
        background: #2E88CE;
        border-radius: 4px;
        margin-right: 12px;
    }

    .mat_excess>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {
        width: 12px;
        height: 12px;
        background: #DFCB27;
        border-radius: 4px;
        margin-right: 12px;
    }

    .mat_excess>div:nth-of-type(2)>div:nth-of-type(2) {
        color: #1DA5F9;
        font-size: 40px;
        margin-left: 15px;
    }

    .table-top-right-buttom {
        position: relative;
        padding: 10px;
    }

    .pro_number {
        position: absolute;
        z-index: 10;
        display: flex;
        align-items: center;
    }

    .pro_number>div {
        width: 100px;
        height: 74px;
        background-image: url(../img/pro_number.png);
        background-size: 100% 100%;
        text-align: center;
        padding: 10px 0;
        margin-right: 10px;
    }

    .pro_number>div>div:nth-of-type(1) {
        font-size: 16px;
        opacity: .6
    }

    .pro_number>div>div:nth-of-type(2) {
        font-size: 20px;
        font-weight: bold;
    }

    .map {
        width: 100%;
        height: 100%;
    }

}


@media only screen and (max-width:1500px) {
    .ant-tree-title {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px !important;
    }

    .sideMenu {
        flex: auto !important;
        max-width: 250px !important;
        min-width: auto !important;
        width: 250px !important;
        height: 100%;
        background: #339EF9 !important;
        border-radius: 0 30px 30px 0;
        overflow: auto;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .sideMenu_plate>div {
        width: 90%;
    }

    .sandbox {
        width: calc(100vw - 250px);
        height: 100%;
        padding: 40px;
        padding-bottom: 0 !important;
    }

    .contract_List>div:nth-of-type(1) {
        width: 300px;
        padding: 20px 10px;
        min-height: calc(100vh - 150px);
        background-color: #FFFFFF;
        border-radius: 16px;
    }

    .contract_List>div:nth-of-type(2) {
        width: calc(100vw - 690px)
    }

}

/*358看板*/

.project_kanban {
    width: 100%;
    height: calc(100vh - 120px);
    background-color: #033c76;
    border-radius: 16px;
    padding: 15px 24px 25px;
}

.project_kanban>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.project_kanban>div:nth-of-type(1)>div:nth-of-type(1),
.project_kanban_name>div:nth-of-type(1) {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #13BEF3;
    margin-right: 10px;
}

.project_kanban_name {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #FFFFFF;
}

.project_kanban .ant-form-item-label>label {
    color: #FFFFFF !important;
}

.project_kanban .ListSearch .ant-form-item-control-input-content button {
    background-color: #038DCE !important;
    color: #FFFFFF !important;
}

.project_kanban th {
    background-color: #033c76 !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: lighter !important;
    border-bottom: 1px solid rgba(7, 118, 181, .7) !important;
}

.project_kanban td {
    background-color: #033c76 !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border-bottom: 1px solid rgba(7, 118, 181, .7) !important;
}

div.ant-table-summary,
.ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
}

.project_kanban .ant-table-tbody>tr>td {
    border-bottom: 1px solid rgba(7, 118, 181, .7) !important;
}

.project_kanban ::-webkit-scrollbar {
    width: 5px;
}

.project_kanban ::-webkit-scrollbar-track {
    background: #045CAE;
}

.project_kanban ::-webkit-scrollbar-thumb {
    background: #038DCE;
    border-radius: 2px;
}

.project_kanban_NewItem>div:nth-of-type(1) {
    margin-bottom: 20px;
}

.project_kanban_NewItem>div:nth-of-type(2) {
    height: calc(100vh - 120px);
}

.project_kanban_NewItem>div:nth-of-type(2)>div {
    width: 49%;
    height: 100%;
}

.project_kanban_NewItem .pro_overview {
    height: 68px;
    line-height: 50px;
    font-weight: bold;
}

.project_kanban_NewItem .pro_overview:before {
    top: 32px;
}

.project_kanban_NewItem .pro_introduce>div>div:nth-of-type(1) {
    width: 120px;
}

.project_kanban_NewItem .pro_introduce,
.eng_Information {
    height: calc(100% - 68px);
}

.project_kanban_NewItem .pro_introduce>div {
    height: 12.5%;
    line-height: 4.2;
    align-items: center;
}

.project_kanban_NewItem .pro_introduce>div>div:nth-of-type(2) {
    font-size: 18px;
    font-weight: bold;
}

.project_kanban_NewItem .pro_introduce>div:nth-of-type(7)>div:nth-of-type(2) {
    color: #07D7F3;
}

.project_kanban_NewItem .pro_introduce>div:nth-of-type(8) {
    border-bottom: none;
}

.project_kanban_NewItem .pro_introduce>div>img {
    width: auto;
    height: 20px;
    margin-right: 20px;
}

.eng_Information {
    padding: 16px;
}

.eng_Information>div {
    border-bottom: 1px dotted #407abd;
    font-size: 16px;
    display: flex;
    height: 25%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.eng_Information>div>img {
    width: 52px;
    height: 52px;
}

.eng_Information>div>div {
    width: calc(100% - 120px);
    ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eng_Information>div:nth-of-type(4) {
    border-bottom: none;
}

.eng_Information>div>div>div {
    width: 30%;
}

.eng_Information>div>div>div>div:nth-of-type(2) {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}